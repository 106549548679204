import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import ModalImageCors from "../ModalImageCors";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  isBot: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  viewMediaInput: {
    display: "flex",
    alignItems: "stretch",
    gap: 10,
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  viewMediaInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "100%",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  thumbnailImage: {
    width: "70px",
    height: "70px",
    objectFit: "cover",
    borderRadius: "5px",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  emojiPicker: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  },
}));

const MessageInput = ({ ticketStatus, channel, isBot }) => {
  const classes = useStyles();
  const { ticketId } = useParams();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [enterTriggerOnMessage, setEnterTriggerOnMessage] = useState("enabled");

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  useEffect(() => {
    const settingKey = "enterTriggerOnMessage";

    const fetchSettings = async () => {
      try {
        const { data } = await api.get(`/settings/${settingKey}`);
        setEnterTriggerOnMessage(data?.value);
      } catch (err) {
        toastError(err);
      }
    };

    fetchSettings();
  }, [setEnterTriggerOnMessage]);

  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value.message);

    if (value.files && value.files.length > 0) {
      setMedias(value.files);
    }

    setTypeBar(false);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    formData.append("body", inputMessage);
    medias.forEach((media) => {
      if (media instanceof File) {
        formData.append("medias", media);
      } else {
        formData.append("mediaUrls[]", media.mediaUrl);
      }
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    setMedias([]);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1) },
        });
        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", inputMessage);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body ? (
              <span
                className={clsx(classes.replyginMsgText, {
                  [classes.replyginSelfMsgText]: !message.fromMe,
                })}
              >
                {message.body}
              </span>
            ): message.mediaUrl ? (
              <ModalImageCors imageUrl={message.mediaUrl} />
            ): null}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  if (!isBot) {
    if (medias.length > 0)
      return (
        <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
          <IconButton
            aria-label="cancel-upload"
            component="span"
            onClick={(e) => setMedias([])}
          >
            <CancelIcon className={classes.sendMessageIcons} />
          </IconButton>

          {loading ? (
            <div>
              <CircularProgress className={classes.circleLoading} />
            </div>
          ) : (
            <>
              <div className={classes.viewMediaInput}>
                <div className={classes.viewMediaInputContainer}>
                  {medias.map((media) => (
                    <div key={media}>
                      <span>
                        {media instanceof File ? (
                          media.type.startsWith("image/") ? (
                            <img
                              src={URL.createObjectURL(media)}
                              alt={media.name}
                              onLoad={(e) => URL.revokeObjectURL(e.target.src)}
                              className={classes.thumbnailImage}
                            />
                          ) : (
                            <>
                              <img
                                src={`https://ui-avatars.com/api/?name=${media.name}&background=ff6b6b&color=fff`}
                                alt={media.mediaOriginalName}
                                className={classes.thumbnailImage}
                              />
                            </>
                          )
                        ) : media &&
                          (media.mediaUrl.endsWith(".jpg") ||
                            media.mediaUrl.endsWith(".png") ||
                            media.mediaUrl.endsWith(".jpeg") ||
                            media.mediaUrl.endsWith(".gif")) ? (
                          <img
                            src={media.mediaUrl}
                            alt={media.mediaOriginalName}
                            className={classes.thumbnailImage}
                          />
                        ) : (
                          <>
                            <img
                              src={`https://ui-avatars.com/api/?name=${media.mediaOriginalName}&background=ff6b6b&color=fff`}
                              alt={media.mediaOriginalName}
                              className={classes.thumbnailImage}
                            />
                          </>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
                <div className={classes.messageInputWrapper}>
                  <InputBase
                    inputRef={(input) => {
                      input && input.focus();
                      input && (inputRef.current = input);
                    }}
                    className={classes.messageInput}
                    placeholder={i18n.t("messagesInput.placeholder")}
                    multiline
                    maxRows={5}
                    value={inputMessage}
                    onChange={handleChangeInput}
                    onPaste={(e) => {
                      ticketStatus === "open" && handleInputPaste(e);
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <IconButton
            aria-label="send-upload"
            component="span"
            onClick={handleUploadMedia}
            disabled={loading}
          >
            <SendIcon className={classes.sendMessageIcons} />
          </IconButton>
        </Paper>
      );
    else {
      return (
        <Paper square elevation={0} className={classes.mainWrapper}>
          {replyingMessage && renderReplyingMessage(replyingMessage)}
          <div className={classes.newMessageBox}>
            <Hidden only={["sm", "xs"]}>
              <IconButton
                aria-label="emojiPicker"
                className="classes.emojiPicker"
                component="span"
                disabled={loading || recording || ticketStatus !== "open"}
                onClick={(e) => setShowEmoji((prevState) => !prevState)}
              >
                <MoodIcon className={classes.sendMessageIcons} />
              </IconButton>
              {showEmoji ? (
                <div className={classes.emojiBox}>
                  <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                    <Picker
                      perLine={16}
                      showPreview={false}
                      showSkinTones={false}
                      onSelect={handleAddEmoji}
                    />
                  </ClickAwayListener>
                </div>
              ) : null}

              <input
                multiple
                type="file"
                id="upload-button"
                disabled={loading || recording || ticketStatus !== "open"}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
              />
              <label htmlFor="upload-button">
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={loading || recording || ticketStatus !== "open"}
                >
                  <AttachFileIcon className={classes.sendMessageIcons} />
                </IconButton>
              </label>
              <FormControlLabel
                style={{ marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    checked={signMessage}
                    onChange={(e) => {
                      setSignMessage(e.target.checked);
                    }}
                    name="showAllTickets"
                    color="primary"
                  />
                }
              />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenuClick}
              >
                <MoreVert></MoreVert>
              </IconButton>
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuItemClick}
              >
                <span className={classes.emojiPicker}>
                  <MenuItem onClick={handleMenuItemClick}>
                    <IconButton
                      aria-label="emojiPicker"
                      component="span"
                      disabled={loading || recording || ticketStatus !== "open"}
                      onClick={(e) => setShowEmoji((prevState) => !prevState)}
                    >
                      <MoodIcon className={classes.sendMessageIcons} />
                    </IconButton>
                  </MenuItem>
                </span>
                <MenuItem onClick={handleMenuItemClick}>
                  <input
                    multiple
                    type="file"
                    id="upload-button"
                    disabled={loading || recording || ticketStatus !== "open"}
                    className={classes.uploadInput}
                    onChange={handleChangeMedias}
                  />
                  <label htmlFor="upload-button">
                    <IconButton
                      aria-label="upload"
                      component="span"
                      disabled={loading || recording || ticketStatus !== "open"}
                    >
                      <AttachFileIcon className={classes.sendMessageIcons} />
                    </IconButton>
                  </label>
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick}>
                  <FormControlLabel
                    style={{ marginRight: 7, color: "gray" }}
                    label={i18n.t("messagesInput.signMessage")}
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        checked={signMessage}
                        onChange={(e) => {
                          setSignMessage(e.target.checked);
                        }}
                        name="showAllTickets"
                        color="primary"
                      />
                    }
                  />
                </MenuItem>
              </Menu>
            </Hidden>
            <div className={classes.messageInputWrapper}>
              <InputBase
                inputRef={(input) => {
                  input && input.focus();
                  input && (inputRef.current = input);
                }}
                className={classes.messageInput}
                placeholder={
                  ticketStatus === "open"
                    ? i18n.t("messagesInput.placeholderOpen")
                    : channel !== "whatsappOfficial"
                    ? i18n.t("messagesInput.placeholderClosed")
                    : i18n.t("messagesInput.placeholderClosedWhatsappOfficial")
                }
                multiline
                maxRows={5}
                value={inputMessage}
                onChange={handleChangeInput}
                disabled={recording || loading || ticketStatus !== "open"}
                onPaste={(e) => {
                  ticketStatus === "open" && handleInputPaste(e);
                }}
                onKeyPress={(e) => {
                  if (loading || e.shiftKey) return;
                  else if (
                    (e.key === "Enter" || e.keyCode === 13) &&
                    enterTriggerOnMessage === "enabled"
                  ) {
                    handleSendMessage();
                  }
                }}
              />
              {typeBar ? (
                <ul className={classes.messageQuickAnswersWrapper}>
                  {quickAnswers.map((value, index) => {
                    return (
                      <li
                        className={classes.messageQuickAnswersWrapperItem}
                        key={index}
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => handleQuickAnswersClick(value)}>
                          {`${value.shortcut} - ${value.message}`}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div></div>
              )}
            </div>
            {inputMessage ? (
              <IconButton
                aria-label="sendMessage"
                component="span"
                onClick={handleSendMessage}
                disabled={loading}
              >
                <SendIcon className={classes.sendMessageIcons} />
              </IconButton>
            ) : recording ? (
              <div className={classes.recorderWrapper}>
                <IconButton
                  aria-label="cancelRecording"
                  component="span"
                  fontSize="large"
                  disabled={loading}
                  onClick={handleCancelAudio}
                >
                  <HighlightOffIcon className={classes.cancelAudioIcon} />
                </IconButton>
                {loading ? (
                  <div>
                    <CircularProgress className={classes.audioLoading} />
                  </div>
                ) : (
                  <RecordingTimer />
                )}

                <IconButton
                  aria-label="sendRecordedAudio"
                  component="span"
                  onClick={handleUploadAudio}
                  disabled={loading}
                >
                  <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                </IconButton>
              </div>
            ) : (
              <IconButton
                aria-label="showRecorder"
                component="span"
                disabled={loading || ticketStatus !== "open"}
                onClick={handleStartRecording}
              >
                <MicIcon className={classes.sendMessageIcons} />
              </IconButton>
            )}
          </div>
        </Paper>
      );
    }
  } else {
    return (
      <Paper square variant="outlined" className={classes.isBot}>
        <Typography variant="h6" style={{ padding: 15, color: "gray" }}>
          {i18n.t("messagesInput.botMessage")}
        </Typography>
      </Paper>
    );
  }
};

export default MessageInput;
