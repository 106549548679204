import { useEffect, useState } from "react";
import api from "../../services/api";

import openSocket from "../../services/socket-io";

const useSetting = () => {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const fetchSettings = async () => {
            setLoading(true);

            const { data } = await api.get("/settings");

            setSettings(data);
            setLoading(false);
        };

        fetchSettings();
    }, []);

    useEffect(() => {
        const socket = openSocket();
    
        socket.on("settings", (data) => {
          if (data.action === "update") {
            setSettings((prevState) => {
                const aux = [...prevState];
                const settingIndex = aux.findIndex((s) => s.key === data.setting.key);
                aux[settingIndex].value = data.setting.value;
                return aux;
              });
          }
        });
    
        return () => {
          socket.disconnect();
        };
      }, []);

    return { loading, settings };
};

export default useSetting;